*,
*::before,
*::after {
   box-sizing: border-box;
}

* {
   margin: 0;
   padding: 0;
   font: inherit;
}

html,
body {
   height: 100%;
}

html:focus-within {
   scroll-behavior: smooth;
}

body {
   color: $c2;
   text-rendering: optimizeSpeed;
   line-height: 1.5;
   font-family: 'Poppins', sans-serif;
}

div,
section,
header,
article,
footer,
main,
nav,
form,
button {
   display: flex;
   gap: 0.5em;
}

ul[role="list"],
ol[role="list"] {
   list-style: none;
}

img,
picture,
svg {
   display: block;
   max-width: 100%;
}

button,
a,
label {
   color: inherit;
   cursor: pointer;
   background-color: transparent;
   border: none;
   text-decoration: none;

   &:hover {
      opacity: 0.9;
   }
}

input,
select,
textarea {
   outline: none;
   border: none;
   background: none;
}

dialog {
   margin: auto;
   border: none;
   user-select: none;
   visibility: unset;


   button:focus-visible {
      border-radius: none;
      border: none;
      outline: none;
   }
}

dialog::backdrop {
   background: rgb(0 0 0 / 0.4);
}

:focus-visible {
   border-radius: none;
   border: none;
   outline: none;
}

::-webkit-scrollbar {
   width: 0.75rem;
   height: 0.75rem;
}

::-webkit-scrollbar-track {
   background: $c5;
}

::-webkit-scrollbar-thumb {
   background: white;
}