.carousel {
   width: 100%;
   height: 100%;
   position: relative;

   .slides {
      width: 100%;
      height: 100%;
      display: flex;
      overflow: hidden;
      gap: 0;

      .slide {
         object-fit: cover;
         width: 100%;
         display: block;
         flex-shrink: 0;
         flex-grow: 0;

         img {
            object-fit: contain;
            width: 100%;
            height: 100%;
         }
      }
   }



   .btn {
      position: absolute;
      top: 0;
      bottom: 0;
      padding: 0.5rem;
      cursor: pointer;
      transition: background-color 100ms ease-in-out;
      align-items: center;
      border: none;

      &.right {
         right: 0;
      }

      &.left {
         left: 0;
      }

      &:hover,
      &:focus-visible {
         background-color: rgb(0, 0, 0, 0.2);
      }

      &:focus-visible {
         // outline: auto;
      }

      >* {
         stroke: white;
         fill: black;
      }
   }

   .indicators {
      position: absolute;
      bottom: .5rem;
      left: 50%;
      translate: -50%;
      display: flex;
      gap: .25rem;
   }




   @media not (prefers-reduced-motion) {
      .slide {
         transition: translate 300ms ease-in-out;
      }

      .btn:hover>*,
      .btn:focus-visible>* {
         animation: squish 200ms ease-in-out;
      }
   }
}