.home {
   .banner {
      padding-bottom: 2em;
      padding-top: 1em;
      padding-inline: 1em;

      justify-content: space-around;
      gap: 1em;
      background-image:
         linear-gradient(#ffffff50 50%, #ffffff85 75%, #ffffff90 100%),
         url('../../assets/imgs/jerusalem/j1.webp'), ;
      background-attachment: fixed;

      @media (min-width:700px) {
         gap: 0;

      }

      >* {
         flex: 0;
      }

      h1 {
         color: $c7;
         font-size: 5vw;
         font-family: 'Gibson-bold';
         text-align: center;
         text-shadow: $shadow-light;

         @media (max-width:700px) {
            font-family: 'Gibson';

            text-stroke: 2px $c5;
            -webkit-text-stroke: 2px $c5;
            font-size: 9.5vw;
         }

      }

      .isreal {
         text-align: center;
         font-size: 5.5vw;
         font-family: 'Gibson-bold';
         color: white;
         text-shadow: $shadow-dark;
         -webkit-text-stroke: 2px $c5;
         text-stroke: 2px $c5;

         svg {
            margin: auto;
         }

         @media (max-width:700px) {
            font-size: 10vw;
         }
      }

      h2 {
         color: $c7;
         font-size: 5vw;
         font-family: 'Patua';
         font-weight: 700;
         text-align: center;

         @media (max-width:700px) {
            font-size: 10vw;
         }
      }

      .rotating-text-container {

         p {
            color: $c7;
            font-family: 'Gibson-bold';
            font-size: 4vw;
            text-shadow: $shadow-light;

         }

         span {
            color: $c5;
            font-weight: 600;
            text-align: center;
         }

      }

      a.btn {
         margin: 1em;
      }
   }

   .info {
      gap: 0;

      >* {
         width: 50%;
      }

      div {
         flex-direction: column;

         p {
            color: $c2;
            padding: 0.5em;
            font-size: 1.75em;
            font-weight: 600;

            @media (max-width:700px) {
               font-size: 3.25vw;
            }
         }


      }


   }

}