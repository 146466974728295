.btn {
   flex: 0;
   font-size: 1.25em;
   align-self: center;
   padding: 0.25em 0.75em;
   text-align: center;
   transition: 0.5s;
   color: white;
   border-radius: 10px;
   display: block;
   box-shadow: $shadow-light;
   background:$c5;
   cursor: pointer;
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;

   &:hover {
      background-position: right center;
      color: $c2;
      // border: 2px solid $c2;
      // box-shadow: $shadow-light;

      text-decoration: none;
   }

   &:active {
      transform: scale(0.95);
   }
}