// COLORS

$c1: #a8884f;
$c2: #3a3a3a;
$c3: #716044;
$c4: #bc9653;
$c5: #2a8dd0;
$c6: #C0C0C0;
$c7: #384249;
$shadow-dark: 2px 2px 10px rgba(0, 0, 0, 0.64);
$shadow-light: 2px 2px 10px rgba(255, 255, 255, 0.64);

// SPACING

$space-1: 1em;