.rotating-text {
   position: relative;
   display: inline-block;
   font-size: 3em;
   justify-content: center;

   span {
      display: inline-block;
      white-space: nowrap;
      position: absolute;
      top: 50%;
      margin-left: 1%;
      margin-top: 0.33%;
      animation: slideUp var(--rotating-text-frequency) ease-in-out infinite;
   }
}

@keyframes slideUp {
   0% {
      opacity: 0;
      transform: translateY(-40%);
   }

   5% {
      opacity: 1;
      transform: translateY(-50%);
   }

   85% {
      opacity: 1;
      transform: translateY(-50%);
   }

   95% {
      opacity: 0;
      transform: translateY(-60%);
   }

   100% {
      opacity: 0;
      transform: translateY(-60%);
   }
}