.footer {
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: $c5;
   gap: 0.5em !important;
   color: white;
   font-size: 2.85vw;
   padding: 1em;

   .item {
      display: flex;
      gap: 0.5em;
      align-items: center;
      font-size: 2vw;
      svg{
         animation: tilt-shake 2s infinite;
      }

   }


}