.services {
   .banner {
      background-image:
         linear-gradient(#ffffff29 75%, #3a3a3a80, #3a3a3a 95%),
         url('../../assets/imgs/services.jpg');
   }

   .cards {
      grid-gap: 3em;
      align-items: center;
      display: grid;
      grid-template-columns: repeat(3, minmax(250px, 350px));
      justify-content: center;
      padding: 2em;

      @media (max-width:700px) {
      grid-template-columns: repeat(1, minmax(250px, 350px));
         
      }

      .card {
         background-color: initial;
         height: 250px;
         -webkit-perspective: 1000px;
         perspective: 1000px;
         text-align: center;
         width: 100%;
         &:hover{
         .inner{
            -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
         }
         }

         .inner {
            height: 100%;
            position: relative;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            transition: -webkit-transform 1s;
            transition: transform 1s;
            transition: transform 1s, -webkit-transform 1s;
            width: 100%;
         }

         .front {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            background: white;
            border-radius: 10px;
            box-shadow: 0 0 10px #00000040;
            height: 100%;
            position: absolute;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            width: 100%;

            &::after {
               border-radius: 10px;
               box-shadow: inset 0 0 50px #2a8dd0;
               content: "";
               height: 100%;
               inset: 0;
               position: absolute;
               width: 100%;
            }

            svg{
            height: 100%;
            width: 100%;
            }

            h1 {
               color: $c2;
               font-size: 1.75em;
               font-weight: 800;
               left: 50%;
               position: absolute;
               text-shadow: 0 0 10px white, 0 0 10px #2a8dd0, 0 0 10px white;
               text-transform: uppercase;
               top: 50%;
               -webkit-transform: translate3d(-50%, -50%, 50px);
               transform: translate3d(-50%, -50%, 50px);
            }
         }

         .back {
            color: #fff;
            padding: 2em;
            -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            background: $c5;
            border-radius: 10px;
            box-shadow: 0 0 10px #00000040;
            height: 100%;
            position: absolute;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            width: 100%;

            p {
               font-size: 1.5em;
               font-weight: 600;
               -webkit-transform: translateZ(30px);
               transform: translateZ(30px);
            }

            &::after {
               border-radius: 10px;
               box-shadow: inset 0 0 50px #000;
               content: "";
               height: 100%;
               inset: 0;
               position: absolute;
               width: 100%;
            }
         }
      }
   }
}