.nav-bar {
   background: $c5;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 1em;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   z-index: 10;
   flex: 0;

   @media (max-width: 900px) {
      padding: 0.5em;
   }



   .logo-section {

      img {
         width: 118px;
      }
   }

   .nav {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      transform: scale(1, 0);
      transform-origin: top;
      transition: transform 400ms ease-in-out;
      padding: 1em;
      text-align: left;
      margin-bottom: 0.5em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1em;

      &.open {
         background: $c5;
         transform: scale(1, 1);

         a {
            opacity: 1;
            transition: opacity 250ms ease-in-out 250ms;
         }
      }

      a {
         color: $c7;
         font-size: 1em;
         font-weight: 600;
         text-transform: uppercase;
         opacity: 0;
         transition: opacity 150ms ease-in-out;
         width: fit-content;

         &:hover,
         &.active {
            color: white;
            font-weight: 500;
         }
      }
   }

   .btns {
      display: flex;
      align-items: center;
      gap: 10px;
   }
}


@media (min-width: 900px) {

   .nav-bar {
      .hamburger {
         display: none;
      }

      .nav {
         all: unset;
         position: relative;
         top: initial;
         left: initial;
         transition: none;
         background: none;
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         gap: 1em;
         width: 100%;
         min-width: 450px;
         max-width: 650px;
         margin: 0.5em 1em;
         text-align: left;
         transform: scale(1, 1);

         a {
            position: relative;
            opacity: 1;
            padding: 0.25em 1em;

            &:hover,
            &.active {
               &::after {
                  transform: scale(1, 1);
               }
            }

            &::after {
               content: '';
               background: white;
               display: block;
               position: absolute;
               bottom: -35%;
               left: -5%;
               right: -5%;
               height: 2px;
               transform: scale(0, 1);
               transition: transform ease-in-out 250ms;
            }
         }
      }

   }
}

.nav-bar .nav-bar .drop-down {
   position: relative;
   width: 250px;
}

.nav-bar .nav-bar .drop-down>button {
   width: 100%;
}