.App {
   display: flex;
   flex-direction: column;
   min-height: 100vh;
   width: 100vw;
   gap: 0;
}

.page {
   flex: 1;
   gap: 0;
   margin-top: 80px;
   flex-direction: column;
   @media (max-width: 900px) {
      margin-top: 64px;

   }
}

.banner{
   display: flex;
   flex-direction: column;
   position: relative;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   min-height: 500px;
}

.icon {
   width: 25px;
   height: 25px;
   min-width: 25px;
   min-height: 25px;
   @media (max-width:900px) {
      width: 17.5px;
      height: 17.5px;
      min-width: 17.5px;
      min-height: 17.5px;
   }
}

