.resources {
   background: $c6;
   .banner {
      background-size: contain;
      background-image: 
      linear-gradient(#ffffff20 , #ffffff20 ),
      url('../../assets/imgs/fraiman-logo.png');
      padding: 1em;
      p{
         font-family: "Patua";
         font-size: 1.5em;

         color: $c2;
      }
   }
}